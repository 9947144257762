<template>
    <div class="row" style="padding-top: 20px; padding-bottom:10px">
        <q-card class="col-4">
            <q-card-section style="align-items: center; justify-content: center;display: flex;">
                <q-icon name="storage" style="font-size: 1.5em;" /> 
                <q-space /> 
                <div>{{ tanks.length }} {{ $t('app.tank') }}</div>
            </q-card-section>
        </q-card>
    </div>

    <q-list bordered separator style="background-color: white">
        <q-item clickable v-ripple v-for="(f, ix) in tanks" :key="f.id"  @click="goToTank(f)" >
            <q-item-section>#000{{ ix + 1 }}</q-item-section>
            <q-item-section>{{ f.name }}</q-item-section>
            <q-item-section v-if="f.info['CONN_STATUS'] == 'ONLINE'" style="color: green">{{ $t('app.online') }}</q-item-section>
            <q-item-section v-else style="color: red">{{ $t('app.offline') }}</q-item-section>
        </q-item>
        <q-item class="row justify-end">
            <q-btn v-if="!addTank" outline icon="add" :label="$t('app.add_tank') +' ' + $t('app.tank') " type="submit" color="secondary" @click="addTank=true" />
        </q-item>
    </q-list>

    <div style="text-align: right; margin-top: 10px">
 
    </div>
    <div v-if="addTank" style="padding-top: 0px; padding: 15px">
        <div>
        <q-input filled v-model="tankToken" :label="$t('app.token')" :dense="dense" color="secondary" />
        </div>
        <div class="col row justify-center">
        <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.cancel')" type="submit" color="secondary" v-on:click="addTank = false"/>
        <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.ok')" type="submit" color="secondary" v-on:click="addNewTank()" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user_id: String,
        farm_id: String,
        farm: Object, 
    },
    data() {
        return {
            tanks: [],
            tankToken: "",
            addTank: false
        }
    },
    async created () {
        this.retrieveList();
    },
    methods: {
        async retrieveList() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/tank?farm_id='+this.farm_id);
            let data = await response.json();
            this.tanks = data;
        },
        async addNewTank() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/tank', {
                method: 'post',
                body: JSON.stringify({
                    'user_id': this.user_id,
                    'farm_id': this.farm_id,
                    'token': this.tankToken,
                }),
            });
            if (response.status == 200) {
                this.retrieveList();
                this.tankToken = '';
                this.addTank = false;
            }
        },
        async goToTank(tank) {
            //if (tank.info['CONN_STATUS'] == 'ONLINE') {
            //    this.$router.push({name: 'TankDetail', params: {farm_id: this.farm_id, tank_id: tank.id}})
            //}
            this.$router.push({name: 'TankDetail', params: {farm_id: this.farm_id, tank_id: tank.id}});
        }
    }
}
</script>