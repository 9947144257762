<template>
    <div class="farm" style="padding: 15px">
    <q-list bordered separator>
        <q-item >
            <q-item-section>{{ $t('login.user_id') }}</q-item-section>
            <q-item-section>{{ user.id }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('login.username') }}</q-item-section>
            <q-item-section>{{ user.username }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('login.remark') }}</q-item-section>
            <q-item-section>{{ user.last_name }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('app.farm') }}</q-item-section>
            <q-item-section>
            <div v-if="farm_name_readonly">
                {{ farm_name }}
            </div>
            <q-input v-else outlined v-model="farm_name" />
            </q-item-section>
            <q-item-section>
                <div>
                    <q-btn v-if="farm_name_readonly" outline :label="$t('app.edit')" color="secondary" v-on:click="farm_name_readonly = false" />
                    <q-btn v-else outline :label="$t('form.submit')" color="secondary" v-on:click="update_info('name', farm_name)" />
                </div>
            </q-item-section>
        </q-item>
    </q-list>

  </div>
  <div class="home" style="padding: 15px">
    <TankList :farm_id="farm_id" :user_id="user_id" :user="user" :farm="farm" />
  </div>
  
  <div class="row justify-center" style="padding: 15px">
    <q-btn style="margin-left: 5px" outline :label="$t('app.delete_farm')" type="submit" color="negative" v-on:click="deletePage = true" />
  </div>

  <q-dialog
      v-model="deletePage"
      persistent
      :maximized="true"
    >
    
      <q-card style="backgroundColor: rgb(245, 245, 245)">
        <q-card-section>
            <q-list bordered separator>
            <q-item >
                <q-item-section>{{ $t('login.user_id') }}</q-item-section>
                <q-item-section>{{ user.id }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('login.username') }}</q-item-section>
                <q-item-section>{{ user.username }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('login.remark') }}</q-item-section>
                <q-item-section>{{ user.last_name }}</q-item-section>
            </q-item>
            <q-item >
                <q-item-section>{{ $t('app.farm') }}</q-item-section>
                <q-item-section>{{ farm_name }} </q-item-section>
            </q-item>
        </q-list>
        </q-card-section>

        <q-card-section>
        <div>{{ $t('app.delete_farm') }}:  {{ farm.name }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none justify-center" style="color: red">
           {{ $t('app.delete_confirm_message') }}
        </q-card-section>
        <q-card-section class="q-pt-none justify-center" style="color: red">
           {{ $t('app.delete_type_message') }}
        </q-card-section>
        <q-card-section class="q-pt-none justify-center" style="color: red" >
            <q-input ref="delete_input" outlined v-model="deleteComfirm" :dense="dense" />
        </q-card-section>
        <q-card-section>
        <div class="justify-center">
            <q-btn size="sm" outline :label="$t('app.go_back')" type="submit" color="secondary" @click="deletePage = false"/>
            <q-btn size="sm" style="margin-left: 5px" outline :label="$t('app.delete')" type="submit" color="negative" v-on:click="delete_farm()" />
        </div>
        </q-card-section>
      </q-card>
    </q-dialog>


</template>

<script>
import TankList from '@/components/TankList.vue'

export default {
    components: {TankList},
    data () {
        return {
            user_id: this.$route.params.user_id,
            farm_id: this.$route.params.farm_id,
            farm: {},
            user: {},

            farm_name: '',
            farm_name_readonly: true,

            deletePage: false,
            deleteComfirm: '',
        }
    },
    created () {
        this.retrieve_user_info();
        this.retrieve_farm_info();
    },
    watch: {
        farm () {
            this.farm_name = this.farm.name;
        }
    },
    methods: { 
        async retrieve_user_info() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id, {
                method: 'get',
            });
            let data = await response.json();
            this.user = data;
        },
        async retrieve_farm_info() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id+'/farm/'+this.farm_id, {
                method: 'get',
            });
            let data = await response.json();
            this.farm = data;
            
        },
        async update_info(key, value) {
            let data = {};
            data[key] = value;
            try {
                let response = await this.$fetch('/api/v1/user/'+this.user_id+'/farm/'+this.farm_id, {
                    body: JSON.stringify(data),
                    method: 'PUT'
                });
                if (response.status == 200) {
                    let data = await response.json();
                    this.farm = data;

                    this.farm_name_readonly = true;
                } else {
                    throw "response.status: " + response.status;
                }
            } catch (e) {
                this.alert([this.$t('error.retry')]);
            }
        },
        async delete_farm() {
            if (this.deleteComfirm == 'DELETE') {
                let response = await this.$fetch('/api/v1/user/'+this.user_id +'/farm/'+this.farm.id, {
                    method: 'delete',
                });
                if (response.status == 200) {
                    this.deletePage = false;
                     this.$router.push({name: 'User', params: { id: this.user_id }});
                }
            }
            else {
                this.$refs.delete_input.focus();
            }
        }
    }
}
</script>